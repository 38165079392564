import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Structure } from '@lib/simba/_statics'
import Img from 'gatsby-image'
import BlockContent from '@sanity/block-content-to-react'

const Head = styled.div`
    position: relative;
    z-index: 1;
    padding: ${({theme}) => theme.paddingBox};
    background: white;
    align-items: center;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        display: grid;
        grid-template-columns: 2fr 3fr;
    }
`

const Resume = styled.div`
    padding: ${({theme}) => theme.paddingBox};
`
const Title = styled.h1`
    color: ${({ theme }) => theme.colorTextTitle};
`
const ResumeResume = styled.div``
const ImgContainer = styled.div`
    overflow: hidden;
    height: 66vh;
    position: relative;
`

const SvgMask = styled.svg`
    position: absolute;
    height: 100%;
    width: 350px;
    top: 0; left: 0;
    z-index: 9999;
    display: none;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        display: block;
    }
`

const StyledImg = styled(Img)`
    width: 100%;
    height: 100%;
`

const Body = styled.div`
    position: relative;
    z-index: 2;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        background: rgba(255, 255, 255, 0.7);
        border: calc(${({theme}) => theme.paddingBox}*0.66) solid ${({ theme }) => theme.colorText};
        width: ${({ theme }) => theme.breakpoints.desktop};
        margin: ${({theme}) => theme.paddingBox} auto;
        margin-top: -60px;
        padding: ${({theme}) => theme.paddingBox};
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
`

const InnerBody = styled.div`
    background: white;
    padding: ${({theme}) => theme.paddingBox};
    p:last-of-type {
        margin: 0;
    }
`

const Article = ({ data }) => (
    <Structure hideFooterArticle={true}>
        <Head>
            <Resume>
                <Title>{data.article.title}</Title>
                <ResumeResume>{data.article.resume}</ResumeResume>
            </Resume>
            <ImgContainer>
                <SvgMask viewBox='0 0 20 20' preserveAspectRatio='none'>
                    <path d='M0 0 H 20 L 2 20 H 0 Z' fill='white' />
                </SvgMask>
                <StyledImg
                    fluid={data.article.cover.asset.fluid}
                    imgStyle={{
                        objectFit: 'cover',
                        objectPosition: 'center center'
                    }} 
                />
            </ImgContainer>
        </Head>
        <Body>
            <InnerBody>
                <BlockContent blocks={data.article._rawContent} />
            </InnerBody>
        </Body>
    </Structure>
)

export default Article

export const query = graphql`
    query ArticleContent($id: String) {
        article: sanityContenu(id: {eq: $id}) {
            title
            resume
            _rawContent(resolveReferences: {maxDepth: 5})
            categories {
                title
            }
            seo {
                title
                keywords
                description
            }
            cover {
                asset {
                    url
                    metadata {
                        dimensions {
                            height
                            width
                        }
                    }
                    fluid(maxWidth: 1280) {
                        ...GatsbySanityImageFluid
                    }
                }
            }
        }
    }
`